import React, { useState, createContext } from "react";
import { noop } from "lodash";

/**
 * Holds the main content/body in the page component.
 */
export const PageStyleContext = createContext<{
  maxWidth: string;
  setMaxWidth: React.Dispatch<React.SetStateAction<string>>;
}>({ maxWidth: "", setMaxWidth: noop });

const Main: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [maxWidth, setMaxWidth] = useState("");
  return (
    <PageStyleContext.Provider value={{ maxWidth, setMaxWidth }}>
      <section className="sde-main">
        <div className="sde-container" style={{ maxWidth }}>
          {children}
        </div>
      </section>
    </PageStyleContext.Provider>
  );
};
export default Main;
